<template>
  <div class="main">
    <Breadcrumb class="custom-breadcrumb">
      <BreadcrumbItem>课程管理</BreadcrumbItem>
      <BreadcrumbItem>问卷</BreadcrumbItem>
    </Breadcrumb>
    <div class="bg-shadow func-bar">
      <Button type="primary" @click="$router.push('/course/newQuestionnaire')">
        <Icon type="ios-add-circle-outline" />
        新建
      </Button>
      <Button style="margin-left: 10px" type="primary">
        <svg class="icon spread" aria-hidden="true">
          <use xlink:href="#icon-shanchu"></use>
        </svg>
        删除
      </Button>
    </div>
    <div class="table-container bg-shadow">
      <Table :columns="columns" :data="tableData" :loading="loading" style="overflow: auto"> </Table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Questionnaire',
  data() {
    return {
      tableData: [{}],
      loading: false,
      columns: [
        {
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          title: '问卷名称',
          slot: 'name',
          className: 'name-column',
          sortable: true,
          tooltip: true,
          width: 220
        }
      ]
    }
  }
}
</script>

<style scoped lang="less">
.main {
  padding: 10px 20px;
  .func-bar {
    margin-top: 10px;
    margin-bottom: 20px;
    padding: 10px 20px;
    text-align: left;
  }
}
</style>
